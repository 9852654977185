@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary_color_sec: #7d0d0f;
}
.bg-custum-new{
  background-color: var(--primary_color_sec);
}
body {
  font-family: "Roboto", sans-serif !important;
}
.bg-1 {
  background: var(--primary_color);
}
#hambergar:hover {
  background-color: transparent !important;
}
.sideNav-divider {
  background-color: #ededed;
  bottom: 0;
  height: 1px;
  left: 72px;
  position: absolute;
  right: 0;
  display:none;
}

/* #mySidebar a:nth-child(odd) {
  background: #cbdcdf;
} */
.sideNav-arrow {
  position: absolute;
  right: 25px;
}

.profile_box {
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: url(/public/Images/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile_box span {
  font-size: 13px;
  font-weight: 600;
}
.profile_box i {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
}
.edit_profile {
  background: #fff;
  border-radius: 10px;
  padding: 5px 8px;
  cursor: pointer;
  span {
    font-size: 13px;
    font-weight: 700;
    margin-left: 8px;
  }
}

.wallet_box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .boxes {
    background: var(--primary_color);
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    min-height: 50px;
  }
}

.battle_info {
  display: flex;
  justify-content: center;
  .boxes {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #010101;
    min-width: 62%;
    text-align: center;
    .undefined {
      color: #000;
    }
  }
}

.bg-btn {
  background: var(--primary_color);
}
.card {
  font-family: "Roboto", sans-serif;
}
.info {
  color: #978a05 !important;
}
.back_btn {
  .btn {
    padding: 10px 20px !important;
    background: var(--primary_color_sec);
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px;
  }
}

.page-item{
  a.page-link{
    color: #000 !important;
  }
}


.bg-none {
  background: none;
}

.border_danger{
  border-color: #690202;
}

.t_history {
  background: #d5d5d5;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
}
.sideNav-arrow > img {
  height: 11px;
  max-width: 100%;
  width: 6px;
}
.w3-button {
  /* background-color:blueviolet; */
  color: white;
  /* position:fixed */
  margin: auto;
  right: 0;
  /* right:/20px; */
  padding: 0.25rem;
  transition: transform 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active {
  transform: translateX(-100px);
}

.w3-sidebar {
  width: 70%;
  max-width: 300px;
  height: 100%;
  padding: 10px;
  background-color: var(--primary_color_sec);
  color: black !important;
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  transition: all 0.5s;
  z-index: 99999999 !important;
}
.w3-container {
  padding: 0px;
  top: 0 !important;
  margin: auto;
  background: #fff !important;
}
.w3-teal {
  background: white !important;
}
.w3-bar-block .w3-bar-item {
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  color: #fff !important;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
  gap: 9px;
  border-bottom: 1px solid #c1c1c163;
}
.icon i{
  font-size: 20px;
}
/*.w3-bar-block .w3-bar-item:hover{*/
/*    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118));*/
/*    color: black !important;*/
/*}*/

.arrow {
  position: absolute;
  right: 25px;
}
.arrow > img {
  height: 20px;
  width: 6px;
  max-width: 100%;
}

.icon {
  overflow: hidden;
  position: relative;
}
.icon > img {
  height: 25px;
  width: 25px;
  /* max-width: 100%; */
}
#sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: none;
  transition: all 0.5s ease-in-out 0s;
}
