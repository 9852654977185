:root{
    --btncolor:#7d0d0f;
    --primary_color:#7d0d0f;
}
.main-area {
    background-color: transparent;
    overflow: hidden;
    width: 100%;
}

.splash-overlay {
    /* background: linear-gradient(rgba(0, 0, 0, 0.61),rgba(0, 0, 0, 0.596)); */
    background: linear-gradient(180deg, transparent -500px, #000 600.5px);
    background-size: cover !important;
    background-position: center !important;

    height: 100vh;
    pointer-events: none;
    position: absolute;
    width: 100%;
    max-width: 480px;
    z-index: 1;
}
/*.w3-sidebar > a > div{*/
/*    font-family: "Luckiest Guy", cursive;*/
/*    font-size: small;*/
/*    letter-spacing: 2px;*/
/*}*/
.splash-screen {
    -webkit-animation: splashAnim 22s linear infinite;
    animation: splashAnim 22s linear infinite;
    height: 100%;
}

.center-xy {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.login-footer {
    padding: 0 10px;
    padding-top: 175px;
    bottom: 0%;
    color: #676767;
    font-size: .75em;
    font-weight: 400;
    line-height: 15px;
    position: sticky;
    text-align: center;
    max-width: 480px;
    width: 100%;
    z-index: 2;
}

element.style {
    top: 45%;
    z-index: 3;
}

.splash-screen {
    -webkit-animation: splashAnim 22s linear infinite;
    animation: splashAnim 22s linear infinite;
    height: 100%;
}

figure {
    margin: 0 0 1rem;
}

.font-15 {
    color: #2c2c2c;
    font-size: 1.5em;
    font-weight: 700;
}

.text-white {
    color: #fff !important;
}

.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

element.style {
    width: 85%;
    height: 70px;
    border-radius: 5px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

element.style {
    width: 100px;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.hidden {
    display: none !important;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

.refer-button {
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 700;
    height: 48px;
    padding: 0 22px;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
}

.refer-button:hover{
    color: white !important;
    text-decoration: none;
}

.bg-green {
    background-color: #0db25b;
}

.mt-4, .my-4 {
    margin-top: 1.5rem !important;
}
input[name="mobile"] {
   border: 1px solid #d8d6de;
  }
input[name="mobile"]:focus {
   border-color: #7367f0;
  }

  .Login-button{
        border: none;
        border-radius: 5px;
        font-size: 1em;
        font-weight: 500;
        height: 48px;
        padding: 0 22px;
        color: #fff;
        /* border: 3px solid #f4bc41; */
        /* box-shadow: rgb(244 188 65) 0px 0px 1rem; */
        /* font-family: "Luckiest Guy", cursive !important; */
        /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)); */
        background-color: var(--btncolor);
        width: 85%;
        font-weight: 900;
        /* letter-spacing: 1px; */
        text-transform: uppercase;
    }
.bg-color-dark{
    background:var(--btncolor);
}

.loginform{
    top: 50%;
    width: 85%;
    z-index: 9;
    background-color: rgb(230, 230, 230);
    border-radius: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
}