/* *{
    font-family: 'Roboto', sans-serif !important;
} */

.row {
    margin-right: -15px !important;
    margin-left: -15px !important;
}
.p-1.snipcss-A1eLC.snip-div{
    padding-bottom:80px!important;
}
p {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}

button, input {
    overflow: visible !important;
}

button, input, optgroup, select, textarea {
    margin: 0 !important;
    font-family: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
}

.search-slt {
    display: block !important;
    width: 100% !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #ccc !important;
    height: calc(2rem + 2px) !important;
    border-radius: 0 !important;
}

option {
    font-weight: normal !important;
    display: block !important;
    white-space: nowrap !important;
    min-height: 1.2em !important;
    padding: 0px 2px 1px !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer !important;
}





.wrn-btn {
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    height: calc(2rem + 2px) !important;
    border-radius: 0 !important;
}

.btn-info {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
}

.text-info {
    color: #17a2b8!important ;
}

.h4, h4 {
    font-size: 1.49rem;
    font-weight: 500;
    line-height: 1.2;
}

.small, small {
    font-size: 80% !important;
    font-weight: 400 !important;
}

b, strong {
    font-weight: bolder !important;
}

a {
    color: #49b5e7 !important;
}

.far {
    font-weight: 400;
    font-family: "FontAwesome";
}

.btn {
    display: inline-block !important;
    font-weight: 400 !important;
    color: #212529 !important;
    text-align: center !important;
    vertical-align: middle !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem !important;
    font-size: 0.80rem !important;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.ml-auto, .mx-auto {
    margin-left: auto!important;
}
.mr-auto, .mx-auto {
    margin-right: auto!important;
}

.pl-3, .px-3 {
    padding-left: 1rem !important;
}
.pr-3, .px-3 {
    padding-right: 1rem !important;
}

.fa-question-circle:before {
    content: "\f059";
    font-family: "FontAwesome";
    font-style: normal;
}

.fa-play-circle:before {
    content: "\f144";
    font-family: "FontAwesome";
    font-style: normal;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Dosis', sans-serif;
    font-weight: 600;
}


.wallet{
        height:70px;
        mix-blend-mode:normal;
        opacity:1;
        /* background:#fff; */
        border:1px solid #e0e0e0;
        border-radius:5px;
        width:100% ;
}